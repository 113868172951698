import { Query } from 'react-apollo';
import CURRENT_USER_QUERY from '../../lib/currentUserQuery';

const User = (props) => {
  return (
    <Query {...props} query={CURRENT_USER_QUERY}>
      {(payload) => props.children(payload)}
    </Query>
  );
};

export default User;
export { CURRENT_USER_QUERY };
