import gql from 'graphql-tag';

const CURRENT_USER_QUERY = gql`
  query {
    me {
      id
      email
      permissions
      belongsToCompany {
        isSubscribed
        last4
        freeReportsCount
        companyName
      }
    }
  }
`;

export default CURRENT_USER_QUERY;
