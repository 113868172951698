import styled from 'styled-components';
import React from 'react';

import PropTypes from 'prop-types';

const ErrorStyles = styled.div`
  padding: 2rem;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 5px solid red;
  p {
    color: black;
    margin: 0;
    font-weight: 100;
  }
  strong {
    margin-right: 1rem;
  }
`;

const DisplayError = ({ error }) => {
  if (!error || !error.message) return null;
  if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors.length
  ) {
    return error.networkError.result.errors.map((error, i) => {
      if (error.message.includes('Subscription entry not found')) return null;
      return (
        <ErrorStyles key={i}>
          <p data-test="graphql-error">
            <strong>Shoot! </strong>{' '}
            {error.message.replace('GraphQL error: ', '')}
            {error.message.includes('Subscription entry not found') &&
              'Unrecognized companyKey, contact support.'}
          </p>
        </ErrorStyles>
      );
    });
  }

  if (error.message.includes('Subscription entry not found')) return null;
  return (
    <ErrorStyles>
      <p data-test="graphql-error">
        <strong>Shoot!</strong>
        {error.message.includes('Subscription entry not found')
          ? 'Unrecognized companyKey, contact support.'
          : error.message.replace('GraphQL error: ', '')}
      </p>
    </ErrorStyles>
  );
};

DisplayError.defaultProps = {
  error: {},
};

DisplayError.propTypes = {
  error: PropTypes.object,
};

export default DisplayError;
