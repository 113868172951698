import classnames from 'classnames';
import { SVGUniqueID } from 'react-svg-unique-id';

const Logo = (props) => {
  let logoClass = classnames(props.className);

  return (
    <SVGUniqueID>
      <svg
        key={props.keyName}
        className={logoClass}
        fill="none"
        viewBox="0 0 83 43"
      >
        <g fill="#B12028" clipPath="url(#prefix__bga)">
          <path d="M4.754 6.94L0 3.47l3.47 9.58 4.755 3.546L4.754 6.94zM6.791 8.45l11.922 32.74 3.018-1.132-10.186-28.139-4.679-3.394-.075-.076zM13.506 13.428l9.583 26.177 3.018-1.131-7.847-21.5-4.754-3.546zM20.297 18.407l7.168 19.614 3.019-1.132-5.433-14.936-4.754-3.546zM27.088 23.461l4.754 12.976 3.018-1.132-3.018-8.373-4.754-3.47zM33.88 28.44l2.338 6.337 3.018-1.056-.603-1.735-4.754-3.546zM78.246 6.94l-3.47 9.656 4.753-3.545 3.47-9.58-4.753 3.47zM76.134 8.525l-4.679 3.394L61.27 40.058l3.018 1.131L76.21 8.45l-.075.076zM56.893 38.474l3.018 1.131 9.583-26.177-4.754 3.546-7.847 21.5zM52.516 36.89l3.019 1.131 7.168-19.614-4.754 3.546-5.433 14.936zM48.14 35.305l3.018 1.132 4.754-12.976-4.754 3.47-3.018 8.374zM43.764 33.721l3.018 1.056 2.339-6.337-4.754 3.546-.603 1.735zM19.618 43L41.5 35.004 63.382 43H19.618zM45.725 12.523L55.761 5.13h-1.887L47.99 7.167l-2.716-1.434-3.924.83-4.603 1.056c-2.037-.075-3.622-1.81-3.47-3.923.15-1.96 1.66-3.47 3.621-3.47 0 0 .302 0 .302-.15 0-.151-.755 0-.755 0a5.252 5.252 0 00-4.451 5.054 4.87 4.87 0 004.15 4.828l-3.17 12.372 2.113-1.358-3.093 3.696 9.507 6.94 9.432-7.015-3.17-3.697-2.037-8.373z"></path>
        </g>
        <defs>
          <clipPath id="#prefix__bga">
            <path fill="#fff" d="M0 0H83V43H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  );
};

const ArrowRight = (props) => {
  let arrowRightClass = classnames(props.className);

  return (
    <svg
      className={arrowRightClass}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      fill="none"
      viewBox="0 0 17 18"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#fff"
          d="M10.732 4.068l-.713.69a.412.412 0 00.006.603l2.931 2.725H.436A.429.429 0 000 8.508v.984c0 .233.195.422.436.422h12.52l-2.93 2.725a.413.413 0 00-.007.603l.713.69c.17.165.446.165.616 0l4.786-4.634a.412.412 0 000-.596l-4.786-4.634a.446.446 0 00-.617 0z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16.269" height="18" fill="#fff" rx="2"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

const UserIcon = (props) => {
  let userClass = classnames(props.className);
  return (
    <svg fill="currentColor" className={userClass} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const ReportIcon = (props) => {
  let reportsClass = classnames(props.className);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={reportsClass}
      fill="none"
      viewBox="0 0 19 14"
    >
      <path
        fill="#B12028"
        d="M18.555 11.667c.245 0 .445.197.445.437v1.459c0 .24-.2.437-.445.437H.445A.443.443 0 010 13.562V.438C0 .197.2 0 .445 0H1.93c.245 0 .445.197.445.438v11.229h16.18zM13.83 3.482l-3.143 2.06-3.166-4.146a.45.45 0 00-.738.037L3.563 6.708V10.5h14.25l-3.337-6.847a.45.45 0 00-.645-.171z"
      ></path>
    </svg>
  );
};

const CircleCheck = (props) => {
  let checkClass = classnames(props.className);
  return (
    <svg className={checkClass} fill="currentColor" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const SignOutIcon = (props) => {
  let signoutClass = classnames(props.className);
  return (
    <svg className={signoutClass} fill="currentColor" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

let Trash = (props) => {
  let classname = classnames(props.className);
  return (
    <svg className={classname} fill="currentColor" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

let Spinner = ({ isLoading = false }) => {
  return (
    <>
      {isLoading && (
        <div role="status" className="">
          <svg
            aria-hidden="true"
            className="w-5 h-5 ml-2 text-gray-200 fill-current animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              className="text-phoenix-red"
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </>
  );
};

export {
  CircleCheck,
  UserIcon,
  Logo,
  ArrowRight,
  ReportIcon,
  SignOutIcon,
  Trash,
  Spinner,
};
